.send_email {
    position: relative;
    display: inline-block;

    width: 300px;
}
.send_email__input {
    background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgba(234, 234, 234, 0.25);

    width: 100%;

    padding: 9px 48px 9px 18px;

    border-radius: 68px;

    .input__box {
        height: auto;

        border: none !important;
        box-shadow: none !important;
    }
    .input__control {
        color: #5F587B;

        padding: 0;

        border: none;

        font-family: Manrope;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;

        text-transform: lowercase;

        &::placeholder {
            color: #E8E7EC;
        }
    }
    &--small{
        padding: 4px 48px 4px 18px;
    }
}
.send_email__submit {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    z-index: 1;

    cursor: pointer;
}
.send_email__close {
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translateY(-50%);

    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgba(234, 234, 234, 0.25);

    width: 36px;
    height: 36px;

    border-radius: 68px;

    cursor: pointer;
}
