@import "../../assets/styles/common";

.PersonalZoneWrap {
    max-width: 680px;
    width: 100%;

    margin-top: 31px;

    @include mq(mobile) {
        margin-left: 3%;
        margin-right: 3%;
        max-width: 94%;
        padding: 23px 22px;
    }
}
.PersonalZone{
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: auto;
    //padding: 36px 33px 95px;
}

.PersonalZone_header {
    font-family: Manrope;
    font-style: normal;
    font-weight: 800;
    font-size: 19px;
    line-height: 18px;
    margin-bottom: 33px;
    color: #4F4A62;

    @include mq(mobile) {
        font-size: 16px;
        line-height: 14px;
        margin-bottom: 32px;
    }
}

.PersonalZone_info {
    padding-bottom: 26px;

    margin-bottom: 20px;

    border-bottom: 1px solid #F7F6F9;
}
.PersonalZone_info__user {
    display: flex;
}
.PersonalZone_info__title {
    color: #BAB7CB;

    margin-bottom: 14px;
;
    font-family: Roboto;
    font-size: 14px;
    line-height: 18px;
    @include mq(mobile) {
        font-size: 13px;
        line-height: 18px;
    }
}
.PersonalZone_info__block_title {
    color: #5F587B;

    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    @include mq(mobile) {
        margin-bottom: 14px;
        font-size: 13px;
        line-height: 18px;
    }
}
.PersonalZone_info__sub_title {
    color: #BDBBC9;

    margin-top: 2px;

    font-family: Roboto;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
}
.PersonalZone_info__email {
    color: #868FDB;

    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    @include mq(mobile) {
        font-size: 14px;
        line-height: 18px;
    }
}
.PersonalZone_info__avatar {

}

