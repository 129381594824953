@import "../../assets/styles/common";

.Input {
    max-width: 316px;
    &.input_size_s {
        .input__control {
            outline: none;
            font-size: 15px;
            height: 38px;
            padding: 0 50px;
        }

        .input__box {
            padding: 0;
            background: #FFFFFF;
            border: 1px solid #F2F2F2;
            border-radius: 30px;
        }
    }

    .input__control {
        &::placeholder {
            color: $color-placeholder;
        }
    }

    .input__box {
        box-shadow: none !important;
        border: 1px solid $color-border;
        border-radius: $corner-radius;
        background: none !important;
    }

    &.input_focused .input__box {
        border: 1px solid $color-gray-muted;
    }

    &.input_invalid .input__box {

    }
}
