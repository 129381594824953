@import "../../../../assets/styles/common";

.tab {
    display: flex;

    &:not(:first-child) {
        margin-left: 10px;
        .tab__arrow {
            display: block;
        }
        .tab__arrow--solid {
            display: none;
            @include mq(mobile) {
                display: none !important;
            }
        }
        .tab__arrow--dashed {
            @include mq(mobile) {
                display: none;
            }
        }
        .tab__arrow--mobile {
            display: none;
            @include mq(mobile) {
                display: none;
            }
        }
    }
    &:first-child {
        .tab__arrow--mobile {
            @include mq(mobile) {
                display: none;
            }
        }
    }
}
.tab--valid {
    &:not(:first-child) {
        .tab__arrow--dashed {
            display: none;
        }
        .tab__arrow--solid {
            display: block;
        }
    }
}
.tab__arrow {
    display: none;

    margin: 0 30px;

    &--mobile {
        @include mq(desktop) {
            display: none;
        }
        @include mq(mobile) {
            display: block;
        }
    }
}
.tab__count {
    color: #5F587B;

    //padding-right: 15px;

    font-family: Manrope;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;

    @include mq(mobile) {
        font-size: 15px;
    }
}
.tab__block {
    //display: flex;
    //align-items: center;

    //max-width: 180px;

    margin-left: 15px;
}
.tab__title {
    color: #868EDB;
    opacity: 0.6;

    margin-bottom: 7px;

    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    .tab--valid & {
        opacity: 1;
        color: #8E8AA1;

    }
    .tab--active & {
        color: #868EDB;
    }
    .tab--invalid & {
        color: red;
    }

    @include mq(mobile) {
        font-size: 10px;
    }
}
.tab__text_button {
    color: #51A0EC;

    font-family: Roboto;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;

    opacity: 0.5;

    cursor: pointer;

    &--active {
        opacity: 1;
    }
}
