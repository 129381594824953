@import "../../assets/styles/common";

.Button {
    position: relative;
    background: linear-gradient(2.31deg, $gradient-color-1 -46.04%, $gradient-color-2 182.54%), linear-gradient(267.93deg, $primary-color-1 -10.37%, $gradient-color-2 99.2%);
    box-shadow: 0px 12px 26px rgba(142, 138, 161, 0.24), inset 0px 10px 21px rgba(105, 219, 255, 0.37);
    //border: 1px solid $gradient-color-1;
    color: #FFFFFF;

    border: none;
    border-radius: 150px;
    outline: 0;

    font-family: Manrope;
    font-weight: 800;
    letter-spacing: 1px;

    @include mq(desktop) {

    }
    @include mq(mobile) {

    }
}

.Button .tag-button__text {
    font-family: Manrope;
    font-weight: 600;
    letter-spacing: 1px;
}

.Button:hover{
    //background-color: $primary-color-2;
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
    }
    &:hover {
        &:before {
            background: rgba(255, 255, 255, 0.1);
        }
    }
}


