.container {
    margin-left: auto;
    margin-right: auto;

    @include mq(desktop) {
        width: $container-width;
    }
    @include mq(mobile) {
        max-width: $container-width;
        padding-left: $padding-side;
        padding-right: $padding-side;
    }
}

.row {
    @include mq(desktop) {
        display: flex;
        align-items: flex-start;
        & > * {
            flex: 0 0 auto;
        }
    }
}