// Responsive
$breakpoints: (
    mobile: 0 1023px,
    desktop: 1024px null
);

// Fonts
$font-regular: sans-serif;

// Sizes
$container-width: 100%;
$padding-side: 20px;
$corner-radius: 4px;

$textSizeLG: 29px;

$textSizeMD: 24px;

$textSizeSM: 16px;

$textSizeXS: 11px;

$text-size: 19px;

// Colors
$color-text-dark: #4F4A62;
$color-text-grey: #ADA8BE;
$color-text-light: #868EDB;
$color-text-lightly: #5F587B;
$color-text-bright: #E0E0E0;
$color-text-brightly:#C4CAEE;

$white-color: #FFFFFF;

$primary-color-1: #52A1ED;
$primary-color-2: #868FDB;
$primary-color-3: #5EB1E9;

$gradient-color-1: #A19DFE;
$gradient-color-2: #77AAFF;
$gradient-color-3: #00C2DE;


$color-gray-light: rgba(#00103D, 0.06);
$color-gray-muted: #919399;
$color-border: rgba(#00103D, 0.12);
$color-placeholder: #999999;
$color-warning: #FFFCE0;
$color-blue: #005BD1;
$color-overlay: rgba(#00103D, 0.48);

$radius: 13px;