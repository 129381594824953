@import "../../../assets/styles/common";

.HeaderSignout {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 75px;
    top: 90px;
    width: 200px;
    min-height: 214px;
    padding: 14px 14px 30px 14px;
    background-color: #FFFFFF;
    box-shadow: 0px 33px 48px rgba(211, 219, 238, 0.15);
    border-radius: 6px;
    @include mq(mobile) {
        right: 0;
        top: 77px;
    }
}

.HeaderSignout::after {
    content: " ";
    position: absolute;
    left: 64px;
    top: -10px;
    border-top: 0;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid #FFFFFF;
    @include mq(mobile) {
        left: 151px;
    }
}

.HeaderSignout_link {
    display: flex;
    align-items: center;
    padding: 16px 10px;
    border-bottom: 1px solid #F7F6F9;
    color: #4F4A62;
}

.HeaderSignout_link:last-child {
    border-bottom: none;
    cursor: pointer;
}

.HeaderSignout_text {
    font-size: 15px;
    margin: 0 15px;
    font-weight: bold;
    font-family: Manrope;
    font-weight: 800;

    .HeaderSignout_link:hover & {
        color: #64CFFA;
    }

    @include mq(mobile) {
        font-size: 13px;
        line-height: 13px;
    }
}

.HeaderSignout_text_t{
    font-size: 13px;
    color: #BDBBC9;
    font-family: Roboto;

    @include mq(mobile) {
        margin-top: 4px;
        font-size: 11px;
    }
}

