.layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    //min-height: calc(100vh - 57.5px);
    min-height: 100vh;
    position: relative;
    z-index: 0;
}

.layout__header{
    z-index: 2;
    position: relative;
}

.layout__main {
   //z-index: 1;
   //position: absolute;
   width: 100%;
   height: 100%;
}
.layout__main--auto {
    position: static;

    height: auto;
}

.layout__footer {
    z-index: 3;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;

    &--hide {
        display: none !important;
    }
}
.layout__footer--auto {
    position: static;
}
