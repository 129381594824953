.preview {
    position: relative;
    margin-bottom: 40px;
}
.preview__signed_icon {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
}
.preview__card {
    background: #ffffff;
    box-shadow: 0px 98px 250px rgba(211, 219, 238, 0.46);

    padding: 7px;

    margin-bottom: 10px;

    border-radius: 25px;
}
.preview__controls {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -6.06%, rgba(255, 255, 255, 0.93) 46.21%, #FFFFFF 122.73%);
    padding-bottom: 22px;
    height: 125px;
}
