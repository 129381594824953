@import "../../assets/styles/common";

.page_registration {
    display: flex;
    justify-content: center;

    margin-top: 98px;
    background: url(../../assets/img/bg-fade1.png) center center no-repeat;

    @include mq(mobile) {
        margin-top: 17px;
        margin-bottom: 43px;
    }
}
.page_registration__card {
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F3F4F8;
    box-shadow: 0px 96px 250px rgba(211, 219, 238, 0.6);

    max-width: 680px;
    width: 100%;

    padding: 36px 33px 53px;

    border-radius: 13px;

    @include mq(mobile) {
        position: relative;
        overflow-x: hidden;
        padding: 24px 22px 40px;
        width: 96%;
    }
}
.page_registration__title {
    color: #4F4A62;

    margin-bottom: 33px;

    font-family: Manrope;
    font-size: 21px;
    font-weight: 800;
    line-height: 18px;

    @include mq(mobile) {
        font-size: 16px;
        line-height: 14px;
        margin-bottom: 140px;
    }
}
