.List {
    list-style: none;
    margin: 0;
    padding: 0;

    & > * {
        padding: 0;
        margin: 0;
    }
}

.List__vertical {
    & > * {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.List__ordered {
    counter-rest: list;

    & > * {
        counter-increment: list;
        display: flex;
        align-items: flex-start;
        font-size: $textSizeMD;

        &:before {
            content: counter(list) '.';
            display: inline-block;
            flex: 0 0 auto;
            margin-right: 12px;
            color: $color-gray-muted;
        }
    }
}