@import "../../assets/styles/common";

.DocumentsListItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 3px;
    border-bottom: 1px solid #F7F6F9;

    &--open {
        @include mq(mobile) {
            position: relative;
            margin-bottom: 65px;
        }
    }
}

.DocumentsListItem__layer {
    position: absolute;
    top: 110%;
    display: none;
    justify-content: space-around;

    width: 100%;

    padding-top: 10px;

    .DocumentsListItem--open & {
        display: flex;
    }
}
.DocumentsListItem__button {
    font-size: 12px;
    border-radius: 20px;
    padding: 6px 14px;
    background: linear-gradient(2.31deg, #A19DFE -46.04%, #77AAFF 182.54%), linear-gradient(267.93deg, #52A1ED -10.37%, #77AAFF 99.2%);
    color: #fff;
    border: 1px solid #F7F6F9;
    a {
        color: #fff !important;
        text-decoration: none !important;
    }
}

.DocumentsList_index{
    margin-left: 20px;
    align-self: center;
    font-size: 12px;
    color: #BAB7CB;
}

.DocumentsListItem_preview {
    flex: 0 0 auto;
    margin: 5px 20px 0;
    height: 50px;

    @include mq(mobile) {
        height: 43px;
        width: 30px;
        margin: 5px 9px 0 0;
    }
}

.DocumentsListItem_info {
    padding-top: 10px;
    max-width: 70%;
    width: 100%;

    overflow-x: hidden;
}

.DocumentsListItem_title {
    font-size: 14px;
    padding-bottom: 4px;
    align-self: center;
    font-family: Manrope;
    font-weight: 600;
    letter-spacing: 1px;

    @include mq(mobile) {
        font-size: 13px;
        line-height: 18px;
    }
}

.DocumentsListItem_meta {
    color: $color-gray-muted;
    display: flex;
    flex-wrap: wrap;
    font-size: $textSizeXS;
    & > * {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
    @include mq(mobile) {
        font-size: 10px;
        line-height: 13px;
    }
}

.DocumentsListItem_overlay {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 0.3s ease;
    background: transparent;
    box-shadow: 0px 13px 37px rgba(211, 219, 238, 0.46);

    @include mq(mobile) {
        display: none;
    }
}

.Overlay {
    display: none;
    align-self: center;
    margin-right: -5px;
    width: 5px;
    height: 27px;
    background: linear-gradient(142.97deg, #30B6EC 2.89%, #AB5CFF 100%), #30B6EC;
    border-radius: 34.5px;
}

.DocumentsListItem:hover .DocumentsListItem_overlay{
    display: flex;

    cursor: pointer;
    border-radius: 3px;

    @include mq(mobile) {
        display: none;
    }
}
 //.DocumentsListItem:hover {
 //    opacity: 1;
 //}
 //.DocumentsList_rows:hover {
 //    opacity: 0.5;
 //}

.DocumentsListItem:hover .Overlay{
    display: flex;
}
.DocumentsListItem_icon {
    margin-top: 12px;
}
.DocumentsListItem_icons {
    background: #FFFFFF;
    height: 100%;
    display: flex;
    align-items: center;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.DocumentsListItem_icon_f {
    padding: 0 23px;
    border-right: 1px solid #EAEAEA;

    cursor: pointer;

    &:hover {
        path {
            fill: #E35839;
        }
        rect {
            fill: #FFBA78;
        }
    }
}

.DocumentsListItem_icon_l {
    padding: 0 23px;

    &:hover {
        path {
            &:not(:first-child) {
                stroke: #30B6EC;
            }

            &:first-child {
                fill: #30B6EC;
            }
        }
        rect {
            stroke: #9DE7FE;
        }
    }
}

.DeleteModal{
    z-index: 4;
    position: absolute;
    margin: auto;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 436px;
    height: 300px;
    padding: 31px;
    background-color: $white-color;
    box-shadow: 0px 13px 37px rgba(211, 219, 238, 0.46);
    border-radius: 3px;
    outline: transparent;

    @include mq(mobile) {
        width: 314px;
        height: 251px;
        padding: 25px 20px 47px;
    }
}

.DeleteModal-close {
    align-self: flex-end;

    cursor: pointer;
}

.DeleteModal_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 85px;
    text-align: center;

    @include mq(mobile) {
        margin: 0;
    }
}

.DeleteModal_header {
    font-size: $text-size;
    margin: 7px 0 34px;
    font-family: Manrope;
    font-weight: 800;

    @include mq(mobile) {
        font-size: 16px;
        margin-bottom: 33px;
    }
}

.DeleteModal_text {
    font-size: $textSizeSM;
    line-height: 24px;
    margin-bottom: 51px;
    color: $color-text-lightly;
    font-family: Manrope;
    font-weight: 600;

    @include mq(mobile) {
        font-size: 13px;
        line-height: 18px;
        margin: 0 55px 42px;
    }
}

.DeleteModal_btns{
    display: flex;
    justify-content: center;
    margin-bottom: 22px;

    @include mq(mobile) {
        margin-bottom: 0;
    }
}

.DeleteModal_cancel {
    position: relative;
    border: none;
    outline: none;
    background-color: $white-color;
    color: #51A0EC;
    padding: 10px 31px;
    font-size: 13px;
    font-family: Roboto;
    font-weight: 500;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
    }
    &:hover {
        &:before {
            background: rgba(255, 255, 255, 0.1);
        }
    }

    @include mq(mobile) {
        font-size: 13px;
    }
}

.DeleteModal_delete {
    position: relative;
    padding: 10px 39px;
    color: $white-color;
    background: linear-gradient(92.79deg, #FF3D00 26.36%, #FF7A00 78.52%), linear-gradient(2.31deg, #A19DFE -46.04%, #77AAFF 182.54%), linear-gradient(267.93deg, #52A1ED -10.37%, #00C2DE 99.2%);
    box-shadow: 0px 9.58802px 20.774px rgba(142, 138, 161, 0.24), inset 0px 7.99001px 16.779px #FFBA78;
    border-radius: 119.85px;
    border: none;
    outline: none;
    font-size: 14px;
    font-family: Manrope;
    font-weight: 800;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
    }
    &:hover {
        &:before {
            background: rgba(255, 255, 255, 0.1);
        }
    }
    @include mq(mobile) {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 0;
        padding: 6px 38px 9px;
    }
}
