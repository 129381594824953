@import "../../assets/styles/common";

.PageDocuments {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-top: 80px;

    @include mq(mobile) {
        padding-top: 0;
    }
}
.PageDocuments__bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../../assets/img/bg-fade1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}

