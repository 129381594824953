@import "../../assets/styles/common";

.dropzone__view {
    display: none;
}

.DocumentDropzone {
    width: 100%;
    max-width: 527px;
    background: linear-gradient(180deg, #FCFCFC 27.5%, #FEFEFE 100%);
    border: 2.5px dashed #E2E7F8;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    min-height: 184px;

    @include mq(mobile) {
        border: none !important;
        width: 100%;
        height: fit-content;
        justify-content: start;
    }
}

.DocumentDropzone_active {
    //border-color: rgba(0, 16, 61, 0.48);
    cursor: pointer;
}

.DocumentDropzone_content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq(mobile) {
        align-items: flex-start;
        width: 100%;
    }
}

.DocumentDropzone_linkWrapper {
    display: flex;
    justify-content: center;
}

.DocumentDropzone_appendLink {
    margin-top: 33px;
    display: flex;

    color: #2C2D2E;

    &:hover {
        color: #FC2C38;
    }
}

.DocumentDropzone_appendLinkIcon {
    width: 14px;
    margin-right: 13px;
}

.DocumentDropzone_appendLinkText {
    font-size: 15px;
    line-height: 20px;
    text-decoration: underline;
}

.DocumentDropzone_files {
    display: flex;
    width: 471px;
    margin: 47px 0 61px;
    background: $white-color;
    box-shadow: 0px 13px 37px rgba(211, 219, 238, 0.46);
    border-radius: 21px;
    @include mq(mobile) {

    }
}

.DocumentDropzone_doc {
    margin: 10px 16px 0 10px;
}

.DocumentDropzone_file{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    box-shadow: inset -50px 0 9px -7px #FFFFFF;
}

.DocumentDropzone_fileName {
    font-size: 14px;
    color: #5F587B;
    overflow-y: hidden;
}

.DocumentDropzone_size{
    font-size: $textSizeXS;
    color: #BDBBC9;
}

.DocumentDropzone_mark {
    margin-left: 21px;
    margin-right: 5px;
}

.DocumentDropzone_delete{
    margin-left: auto;
    margin-right: 20px;
    align-self: center;
    cursor: pointer;
}

.DocumentDropzone_hint {
    font-size: 15px;
    line-height: 20px;
    color: #919399;
    text-align: center;

    @include mq(mobile) {
        display: none;
    }
}

.DocumentDropzone_hint_f {
    font-size: 13px;
    color: $color-text-lightly;
    margin: 15px 0 5px;
    font-family: Manrope;
    font-weight: 600;
}

.DocumentDropzone_hint_s {
    font-size: $textSizeXS;
    color: $color-text-light;
    margin: 0;
    font-family: Roboto;
    font-weight: 500;
}

.DocumentDropzone_hint_l {
    font-size: $textSizeXS;
    color: $color-text-brightly;
    margin: 20px 0 15px;
    font-family: Manrope;
}
