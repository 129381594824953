@import "../../assets/styles/common";
.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;

    background: rgba(255, 255, 255, 0.7);
}
.modal_animate {
    position: absolute;
    top: 110%;
    //bottom: -110%;
    left: 50%;
    transform: translate(-50%, 0);
    transition: .5s;

    background: #ffffff;
    box-shadow: 0px 54px 250px rgba(211, 219, 238, 0.6);

    max-width: 534px;
    width: 100%;

    padding: 45px 45px 62px 45px;

    border-radius: 13px;

    &--show {
        //transform: translate(-50%, -360%);
        transform: translate(-50%, -150%);
    }
}
.modal_animate__close {
    position: absolute;
    top: 54px;
    right: 54px;

    cursor: pointer;

    @include mq(mobile) {
        top: 33px;
        right: 26px;
    }
}
