@import "../../../assets/styles/common";

.tabs__switch_wrap {
    display: flex;
    justify-content: center;

    margin-right: -30%;
    margin-left: -30%;

    @include mq(mobile) {
        position: absolute;
        top: 50px;
    }
    &--left {
        @include mq(mobile) {
            left: 35%;
        }
    }
    &--center {
        @include mq(mobile) {
            left: 7%;
        }
    }
    &--right {
        @include mq(mobile) {
            left: -24%;
        }
    }

}
.tabs__tabs {
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 13px 37px rgba(211, 219, 238, 0.46);

    min-width: 65%;

    padding: 30px 25px;

    border-radius: 13px;

    @include mq(mobile) {
        padding: 23px 16px;
    }
}
.tabs__tab {
    display: flex;

    &:not(:first-child) {
        margin-left: 60px;
    }
}
.tabs__tab_count {
    color: #5F587B;

    font-family: Manrope;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
}
.tabs__tab_block {
    display: flex;
    align-items: center;

    max-width: 170px;

    margin-left: 15px;
}
.tabs__tab_title {
    color: #868EDB;

    font-family: Roboto;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}
.tabs__controllers {
    display: flex;
    justify-content: space-between;

    margin-top: 48px;
}
.tabs__controllers_single {
    text-align: center;

    width: 100%;

    &--relative {
        position: relative;
    }
}
.tabs__controllers_button {
    position: absolute;

    font-family: Roboto, sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 34px;
    height: 34px;
    background: rgb(118, 88, 224);
    color: rgb(255, 255, 255);
    cursor: pointer;
    user-select: none;
    padding: 0px 20px;
    transition: background 0.15s ease 0s, box-shadow 0.15s ease 0s, color 0.15s ease 0s, fill 0.15s ease 0s;
    outline-width: 0px;
    border-radius: 5px;

    @include mq(mobile) {
        left: 50%;
        transform: translateX(-50%);
        bottom: 38px;
    }
}
.tabs__controller {
    display: inline-block;
    color: #868EDB;

    font-family: Roboto;
    font-weight: 500;
    font-size: 13px;
    line-height: 27px;

    cursor: pointer;

    &--disabled {
        opacity: 0.6;
    }
}
