.sign {
}
.sign__title {
    text-align: center;
    text-transform: capitalize;
    color: #4F4A62;

    font-family: Manrope;
    font-weight: 800;
    font-size: 20px;
    line-height: 18px;
}
.sign__description {
    text-align: center;

    margin-bottom: 46px;

    color: #ADA8BE;
    font-family: Roboto;
    font-size: 16px;
    line-height: 28.5px;
}
.sign__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
        margin-right: 8px;
    }
}
.sign__phone {
    max-width: 316px;
    width: 100%;
}
.sign__button {
    max-width: 316px;
    height: 37px;
}
