@import "../../../assets/styles/common";
.detail {
    margin-top: 32px;
    //
    //background-image: url(../../../assets/img/bg-fade1.png);
    //background-size: contain;
    //background-position: center;
    //background-repeat: no-repeat;
    //padding-top: 80px;
}
.detail__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../../../assets/img/bg-fade1.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}
.detail__card {
    position: relative;
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #F3F4F8;
    box-shadow: 0px 96px 250px rgba(211, 219, 238, 0.6);

    max-width: 504px;
    width: 100%;

    padding: 0 20px 74px;

    margin: 0 auto;

    border-radius: 13px;
}
.detail__title_block {
    position: relative;
    top: -15px;

    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 13px 37px rgba(211, 219, 238, 0.46);

    padding: 31px 29px 36px 42px;

    margin: 0 -10px 20px -10px;

    border-radius: 13px;

    @include mq(mobile) {
        padding: 17px 22px 29px 22px;
    }
}
.detail__title {
    color: #5F587B;
    overflow-x: hidden;

    max-width: 100%;

    font-family: Manrope;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;

    @include mq(mobile) {
        font-size: 15px;
        line-height: 25px;
    }
}
.detail__title_edit {
    cursor: pointer;
}
.detail__content {
    display: flex;

    padding: 40px 49px;

    margin-bottom: 22px;

    border: 2.6px solid rgba(118, 182, 255, 0.14);
    border-radius: 14px;

    @include mq(mobile) {
        padding: 29px 27px;
    }
}
.detail__content_signed {
    width: 50%;
}
.detail__content_unsigned {
    width: 50%;
}
.detail__content_title {
    color: #BAB7CB;

    margin-bottom: 24px;

    font-family: Roboto;
    font-size: 14px;
    line-height: 18px;

    @include mq(mobile) {
        font-size: 13px;
        margin-bottom: 18px;
    }
}
.detail__content_block {
    margin-bottom: 28px;
}
.detail__content_name {
    color: #5F587B;

    margin-bottom: 4px;

    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    @include mq(mobile) {
        font-size: 13px;
        line-height: 14px;
        margin-bottom: 2px;
    }
}
.detail__content_description {
    color: #BDBBC9;

    margin-bottom: 7px;

    font-family: Roboto;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;

    @include mq(mobile) {
        font-size: 9px;
        line-height: 11px;
        margin-bottom: 4px;
    }
}
.detail__verification {
    background: linear-gradient(180deg, #FCFCFC 25.42%, #FEFEFE 92.44%);

    width: 100%;

    border: 2.5px dashed #E2E7F8;
    border-radius: 14px;
}
.detail__verification_content {
    display: flex;
    align-items: center;

    margin: 32px 0 36px 49px;
}
.detail__verification_key {
    display: flex;
    align-items: center;

    margin-right: 9px;
}
.detail__verification_text {
    color: #868FDB;

    font-family: Roboto;
    font-weight: 500;
    font-size: 14px;
    line-height: 27px;

    cursor: pointer;

    &:hover {
        color: #5F587B;
    }
}
.detail__verification_help {
    margin-left: 7px;

    cursor: pointer;
}
.detail__controls {
    position: absolute;
    bottom: -21px;
    left: 50%;
    transform: translateX(-50%);
}

//@import "../../assets/styles/common";
//
//.PageDetails {
//
//}
//
//.PageDetails_alert {
//    margin-bottom: 20px;
//}
//
//.PageDetails_nav {
//    margin-bottom: 20px;
//    @include print {
//        display: none;
//    }
//}
