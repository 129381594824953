@import "../../assets/styles/common";

.Link {
    cursor: pointer;
    text-decoration: none;
    color: $color-text-dark;
    &:hover {
        border-bottom-color: transparent;
    }
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.Link__color {
    color: $color-blue;
    border-bottom: 1px solid rgba($color-blue, 0.5);
}

.Link__noline {
    border-bottom-color: transparent;
    &:hover {

    }
}
