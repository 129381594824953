html {
    overflow-y: scroll;
}

body {
    -webkit-font-smoothing: antialiased;
    // font-family: $font-regular;

    @include print {
        background: none;
    }
}

.ReactModal__Body--open {
    overflow: hidden;
}

* {
    box-sizing: border-box;
}