@import "../../../assets/styles/common";

.first_step {
    background-color: #F8FBFF;

    padding: 77px 57px 73px;

    margin-top: -14px;

    @include mq(mobile) {
        padding-left: 0;
        padding-right: 0;
    }
}
.first_step__title {
    color: #4F4A62;

    margin-bottom: 15px;

    font-family: Manrope;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}
.first_step__notice {
    color: #8E8AA1;

    font-family: Roboto;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
}
.first_step__items {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 66px;

    @include mq(mobile) {
        flex-direction: column;
        margin-top: 39px;
    }
}
.first_step__item {
    display: flex;
    color: #5F587B;

    padding: 7.5px 20px;

    &:not(:first-child) {
        margin-left: 10px;
        @include mq(mobile) {
            margin-left: 0;
        }
    }

    border: 2.6px solid rgba(118, 182, 255, 0.14);
    border-radius: 45px;

    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    cursor: pointer;

    &--active {
        background-color: #ffffff;

        padding: 12px 22px;

        border-color: #ffffff;
        @include mq(mobile) {
            padding: 7.5px 10px;
        }
    }
    @include mq(mobile) {
        display: flex;
        justify-content: center;
        margin-bottom: 17px;
        font-size: 13px;
        line-height: 17px;
        width: 169px;
        text-align: center;
    }
}
.first_step__item_inner {
    display: none;

    .first_step__item--active & {
        display: block;
    }
}
