@import "../../assets/styles/common";

.NewDocumentForm_field{
  display: flex;
  align-items: center;
  max-width: 527px;
  height: 37px;
  margin-bottom: 42px;
  background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 67.46%), #FFFFFF;
  box-shadow: 0px 4px 4px rgba(234, 234, 234, 0.25);
  border-radius: 30px;
}

.NewDocumentForm_input {
        &.input_size_s {
            .input__control {
                outline: none;
                font-size: 14px;
                height: 38px;
                padding: 0 50px;
            }

            .input__box {
                background: #FFFFFF;
                border-radius: 30px;
            }
        }

        .input__control {
            &::placeholder {
                margin: 0;
                color: $color-text-light;
                font-size: 14px;
            }
        }

        .input__box {
            padding: 0 15px !important;
            box-shadow: none !important;
            border: none;
            background: none !important;
        }
    }

.NewDocumentForm_icon {
    display: flex;
    align-content: center;
    padding: 0 22px;
    border-left: 2px solid #F2F2F2;
}

.NewDocumentForm_title{
    font-size: $textSizeXS;
    color: $color-text-grey;
    margin: 0 15px 6px;
    font-family: Roboto;
    font-weight: 500;
}

.NewDocumentForm_title_sign {
    color: $primary-color-1;
    margin: 0 5px;
}

.NewDocumentForm_btn {
    display: flex;
    margin: 42px auto;
    padding: 9px 20px;

    border-radius: 150px;

    font-size: 14px;
    font-weight: 800;
}
.NewDocumentForm_btn.tag-button_disabled {
    background: #F1F5FE;
    box-shadow: none;
    .tag-button__text {
        font-size: 14px;
        color: #ffffff;
    }
}

.NewDocumentForm_btn:not(:disabled):hover {
    background-color: transparent;
    .tag-button__text {
        color: rgba(11, 31, 53, 0.3);
    }
}

.NewDocumentForm_btn:disabled {
    border: none
}
