

.DocumentsList {

}

.DocumentsList_rows {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    &:hover {
        .DocumentsListItem {
            opacity: .5;
        }
    }
}

.DocumentsList_row {
    display: flex;
    padding: 0;
    &:last-child {
        margin-bottom: 0;
    }
    &:hover {
        .DocumentsListItem {
            opacity: 1;
            box-shadow: 0px 13px 37px rgba(211, 219, 238, 0.46);
            border-radius: 3px;
        }
    }
}

