@import "../../assets/styles/common";

.SigninContainer {
    width: 100%;
    padding: 159px 0 136px;
    background-image: url("../../assets/img/bg-img.png");
    background-size: auto;
    background-position-y: 5px;
    background-position-x: 50%;
    background-repeat: no-repeat;
    // margin-top: -60px;
    // z-index: -1;
    @include mq(mobile) {
        display: flex;
        justify-content: center;
        background-size: cover;
        background-position-y: center;
        background-position-x: center;

        padding: 43px 0 180px;
    }
}
