@import "../../../assets/styles/common";

.PageAddDocument{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    background-image: url(../../../assets/img/bg-fade1.png);
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 80px;
}

.PageAddDocument_title{
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: $text-size;
    font-family: Manrope;
    font-weight: 800;
}

.PageAddDocument_content{
    margin: 53px 67px 86px ;
}

.PageAddDocument_subtitle {
    font-size: $textSizeXS;
    color: $color-text-grey;
    font-family: Roboto;
    font-weight: 500;
    margin: 10px;
}
