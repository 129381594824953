@import "../../assets/styles/common";

.Frame {
    padding-top: 50px;
    padding-bottom: 36px;
    max-width: 488px;
    min-height: 383px;
    margin: auto;
    background: linear-gradient(180deg, rgba(246, 246, 246, 0.02) 76.62%, rgba(255, 255, 255, 0) 100%), $white-color;
    box-shadow: 0px 54px 250px rgba(211, 219, 238, 0.6);
    border-radius: $radius;
    border: none;

    &--card {
        //padding: 54px 57px 37px;
        padding: 37px 33px;
    }

    @include mq(mobile) {
        height: auto;

        padding: 29px;

        margin: 0 27px;
    }
}
