.code_input {
    display: flex;
}
.code_input__item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgba(234, 234, 234, 0.25);
    border-radius: 8px;
    overflow: hidden;

    width: 36px;
    height: 40px;

    &:not(:first-child) {
        margin-left: 9px;
    }
    .code_input--error & {
        background: linear-gradient(180deg, rgba(255, 115, 115, 0.0605605) 0%, rgba(255, 115, 115, 0) 100%), linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 100%), #FFFFFF;;
    }
}
.code_input__input {
    text-align: center;
    color: #4F4A62;
    background: transparent;

    max-width: 100%;

    border: none;
    outline: 0;

    font-family: Manrope;
    font-weight: 800;
    font-size: 19px;
    line-height: 18px;

    &::placeholder {
        color: #E8E7EC;
    }

    .code_input--error & {
        color: #FF7373;
    }
}
