.dropzone {
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #FCFCFC 27.5%, #FEFEFE 100%);

    padding: 20px 0;

    border: 2.5px dashed #E2E7F8;
    border-radius: 14px;

    cursor: pointer;
}
.dropzone__wrap {
    display: flex;
    align-items: center;
}
.dropzone__block {
    margin-left: 24px;
}
.dropzone__title {
    color: #5F587B;

    font-family: Manrope;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}
.dropzone__text {
    font-family: Roboto;
    font-weight: 500;
    font-size: 11px;
    line-height: 27px;
}
.dropzone__format {
    color: #C4CAEE;

    font-family: Manrope;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}
.dropzone__file {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 13px 37px rgba(211, 219, 238, 0.46);

    max-width: 392px;
    width: 100%;

    padding: 18px 26px;

    border-radius: 21px;
}
.dropzone__file_block {
    display: flex;
    justify-content: space-between;

    width: 100%;

    margin-left: 11px;
}
.dropzone__file_name {
    color: #5F587B;

    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}
.dropzone__file_size {
    color: #BDBBC9;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
}
