@import "../../assets/styles/common";
.signed {
    overflow: hidden;
}
.signed__bg {
    position: fixed;
    left: -200px;
    //transform: rotate(1deg);
    background: url("../../assets/img/bg-signed.png") 50% 67px no-repeat;
    background-size: contain;

    width: 100%;
    height: 100%;

    @include mq(mobile) {
        background-size: 166%;
        background-position-x: 6%;
        left: 0;
        transform: none;
    }
}
.signed__layer_bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../../assets/img/bg-fade1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
}
.signed_form_position {
    position: fixed;
    bottom: 31px;
    left: 40.6%;

    display: flex;
    justify-content: center;
    //transform: translateX(5%);

    max-width: 477px;
    width: 100%;

    margin-top: 359px;
    @include mq(mobile) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        transform: none;
        margin: 0;
    }
}
.send_form__frame {
    position: absolute;
    top: -27px;
    left: 75px;
    z-index: -1;

    //background: url(../../../assets/img/bg-signed-form.png) center center no-repeat;
    background-image: url(../../assets/img/bg-signed-form.png);
    background-repeat: no-repeat;
    background-position-y: 0;

    width: 464px;
    height: 388px;

    @include mq(mobile) {
        max-width: 90%;
        left: 50%;
        transform: translateX(-50%);
        background-size: contain;
    }
}
