@import "../../assets/styles/common";

.Header_legium{
    display: flex;
    justify-content: center;
    @include mq(desktop) {
        padding: 34px 40px 0;
    }
    @include mq(mobile) {
        padding: 20px $padding-side;
    }
    @include print {
        display: none;
   }
}

.DocumentsHeader {
    display: flex;
    &--hide {
        justify-content: center;
    }
    @include mq(desktop) {
        padding: 25px 50px 0;
    }
    @include mq(mobile) {
        padding: 20px $padding-side;
    }
    @include print {
        display: none;
    }
}

.SideHeader {
    display: flex;
    align-items: center;
    margin: 0 115px;
    height: 42px;

    @include mq(mobile) {
        margin: 0;
    }
}

.RateBtn{
    width: 173px;
    height: 42px;
    margin: 0 10px;
    font-size: 15px;
    font-weight: 800;

    @include mq(mobile) {
        font-size: 12px;
        width: 136px;
        height: 32px;
    }
}

.Header_logo {
    max-width: 120px;
    
    font-family: Futura-Light;
    font-size: 27px;

    cursor: pointer;

    @include mq(mobile) {
        font-size: 16px;
    }
}

.Header_controls {
    margin-left: auto;

    &--hide {
        display: none !important;
    }
}

.Header_user {
    @include mq(mobile) {
        display: none !important;
    }
}

.Header_userCompany {
    color: #919399
}

.Header_signout_options {
    position: relative;
    top: 0;
    width: 50px;
    height: 50px;

    @include mq(mobile) {
        //top: 14px;
        width: 38px;
    }
}
.Header_signout_options_avatar {
    position: absolute;
    width: 76px;
    height: 79px;

    img {
        max-width: 100%;
    }
    @include mq(mobile) {
        top: 5px;
        width: 60px;
    }
}
.Header_signout_options_pseudo {
    position: absolute;
    top: 4px;
    left: 16px;

    width: 43px;
    height: 43px;


    cursor: pointer;
}



