@mixin button-reset {
    -webkit-appearance: none;
    background: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    // font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    color: inherit;
    padding: 0;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

@mixin print () {
    @media print {
        @content;
    }
}
