@import "../../assets/styles/common";

.Input {
    background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 67.46%), #FFFFFF;
    box-shadow: 0px 4px 4px rgba(234, 234, 234, 0.25);

    padding: 7.5px 15px;

    border-radius: 30px;

    font-family: Manrope;
    font-size: $text-size;
    font-weight: 800;
    line-height: 18px;

    &::placeholder {
        font-family: Manrope;
        font-weight: 800;
        font-size: 19px;
        line-height: 18px;
    }

    .PhoneInputInput {
        border: none;

        outline: 0;

        font-family: inherit;
        @include mq(mobile) {
            font-size: 16px;
        }
    }
}
