//.controls {
//    position: fixed;
//    bottom: 50px;
//    left: 0;
//    display: flex;
//    justify-content: center;
//
//    width: 100vw;
//}
.controls {
    position: relative;
    background-color: transparent;
    display: flex;
    align-items: start;

    width: auto;
}
.controls__delete {
    position: relative;
    background: #fff;
    box-shadow: 0px 5px 36px rgba(77, 89, 165, 0.06);

    //padding: 8.43px 9.46px;

    width: 35px;
    height: 35px;

    margin-right: 5px;
    margin-top: 9px;

    border-radius: 21.5px;

    cursor: pointer;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.controls__buttons {
    position: relative;

    display: flex;
    align-items: start;
    //background: #fff;
    box-shadow: 0px 5px 36px rgba(77, 89, 165, 0.06);
    //box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

    //padding-right: 17px;

    border-radius: 21.5px;

    &--bg_wight {
        background: #ffffff;
    }
}
.controls__buttons_left {
    display: flex;
    align-items: center;
    background: url(../../assets/img/controls-bg-left.png) center center no-repeat;
    width: 136px;
    height: 35px;
    margin-top: 9px;

    .controls__buttons--bg_wight & {
        background: none;
        width: 128px;
    }
}
.controls__button {
    position: relative;

    display: flex;
    //padding: 8.43px;

    margin-left: 17px;

    cursor: pointer;

    &--sign {
        margin-left: 0;
    }
    &--doc {
        display: flex;
        align-items: center;
        justify-content: center;
        background: url(../../assets/img/controls-bg-right.png) center center no-repeat;

        width: 47px;
        height: 35px;

        margin-top: 9px;
        margin-left: 0;

        .controls__buttons--bg_wight & {
            background: none;
        }
    }
}
.controls__sign {
    position: relative;

    width: 59px;
}
.controls__sign_button {
    position: absolute;
    transform: translateY(-10%);
}
.sign__button {
    width: 100%;

    margin-top: 21px;
}
