@import "../../assets/styles/common";

.Signin-formfield {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.Signin_title {
    font-size: $textSizeMD;
    color: $color-text-dark;
    font-family: Manrope, sans-serif;
    font-weight: 800;
    margin: 0;
    letter-spacing: 1px;

    @include mq(mobile) {
        font-size: 20px;
    }
}

.Signin_subtitle{
    height: 18px;
    font-size: $textSizeSM;
    color:$color-text-grey;
    text-align: center;
    font-family: Roboto;
    font-weight: normal;
    margin: 41px 0 44px;

    @include mq(mobile) {
        font-size: 14px;

        margin: 19px 0 28px;
    }
}

.Signin_subtitle_last {
    width: 373px;
    margin: 23px 0 45px;
    font-size: $textSizeSM;
    line-height: 30px;
    color:$color-text-grey;
    text-align: center;
    font-family: Roboto;
    font-weight: normal;
}

 .Signin_bottom_text{
    margin-top: 71px;
    font-size: 13px;
    color: $color-text-light;
    font-family: Roboto;
    font-weight: 500;

 }

.Signin_btn {
    max-width: 316px;
    width: 100%;
    height: 44px;
    margin-top: 16px;
    font-size: 17px;

    @include mq(mobile) {
        height: 35px;

        margin: 11px 29px 0;

        font-size: 14px;
    }
}

.CheckBox{
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    font-size: 13px;
    margin-top: 46px;
    color: $color-text-grey;

    .checkbox {
        margin: 0;
    }
    .checkbox__box {
        border-color: rgba(11, 31, 53, 0.6) !important;
        background: transparent !important;
        outline: 0;
        width: 18px;
        height: 18px;
    }
    .checkbox_checked {
        .checkbox__box {

        }
        .checkbox__icon {
            background-image: url(../../assets/img/checkbox.png);
        }
    }

    @include mq(mobile) {
        margin-top: 51px;
    }
}

.input_signin {
    max-width: 316px;
    width: 100%;
}

.CheckBox_text {
    font-family: Roboto;
    font-weight: 500;
    margin-left: 13px;

    @include mq(mobile) {
        font-size: 11px;
    }
}

.Mark{
    color: #868EDB;
    text-decoration: none !important;
    font-size: 13px;
}

.Input_error {
   text-align: center;
   color: #FF7373;
   margin-top: 5px;
   font-size: 13px;
   font-family: Roboto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
