.send_form {
    position: relative;
    z-index: 1;

    background: #ffffff;
    box-shadow: 0px 54px 250px rgba(211, 219, 238, 0.6);

    max-width: 477px;
    width: 100%;

    padding-top: 104px;
    padding-bottom: 87px;

    border-radius: 13px;
}

.send_form__close {

}
.send_form__title {
    text-align: center;
    color: #4F4A62;

    margin-bottom: 55px;

    font-family: Manrope;
    font-weight: 800;
    font-size: 21px;
    line-height: 18px;
}
.send_form__description {
    text-align: center;
    color: #C4CAEE;

    margin-bottom: 16px;

    font-family: Manrope;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}
.send_form__block {
    display: flex;
    justify-content: center;
}
.send_form__copy {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(2.31deg, #A19DFE -46.04%, #77AAFF 182.54%), linear-gradient(267.93deg, #52A1ED -10.37%, #00C2DE 99.2%);
    box-shadow: 0px 12px 26px rgba(142, 138, 161, 0.24), inset 0px 10px 21px rgba(105, 219, 255, 0.37);

    margin-right: 13px;

    width: 48px;
    height: 32px;

    border-radius: 150px;

    cursor: pointer;
}
