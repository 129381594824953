@import "../../assets/styles/common";

.documents_empty__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $text-size;
    color: $color-text-dark;
    font-family: Manrope;
    font-weight: 800;
}

.documents_empty__content{
    margin: 83px 0 69px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    min-height: 50vh;
}

.documents_empty__text_f {
    font-size: 15px;
    color: $color-text-dark;
    margin: 0;
    font-family: Manrope;
    font-weight: 600;
}

.documents_empty__text_s {
    font-size: 13px;
    color: $primary-color-2;
    margin: 9px 0 25px;
    font-family: Roboto;
    font-weight: 500;
}

.documents_empty__text_l {
    margin-top: 75px;
    font-size: 12px;
    line-height: $textSizeSM;
    color: $color-text-brightly;
    font-family: Manrope;
}

.documents_empty__upload_icon {
    position: relative;
    display: inline-block;
}

.documents_empty__arrow {
    position: absolute;
    top: 115px;
    right: 7px;
}
