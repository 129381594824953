.Icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    svg {
        display: block;
    }
}

.Icon__md {
    font-size: 16px;
}

.Icon__sm {
    font-size: 8px;
}