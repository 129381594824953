.page {
    margin-top: 90px;
}
.page__title {
    color: #4F4A62;;

    font-family: Manrope;
    font-weight: 800;
    font-size: 21px;
    line-height: 18px;
}
.page__text {
    color: #4F4A62;

    margin-top: 25px;

    font-family: Manrope;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}
