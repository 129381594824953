@import "../../assets/styles/common";

.no_match {
    position: absolute;

    background-image: url('../../assets/img/404.jpg');
    background-position: center;
    background-repeat: no-repeat;

    width: 100%;
    height: calc(100% - 70px);

    @include mq(mobile) {
        background-size: 185%;
        background-position-y: 0;
        background-position-x: 50%;
        height: calc(70% - 70px);
    }
}
.no_match__block {
    position: absolute;
    left: 50%;
    bottom: 25%;
    transform: translateX(-50%);
    text-align: center;

    background: linear-gradient(180deg, rgba(246, 246, 246, 0.02) 76.62%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    box-shadow: 0px 54px 250px rgba(211, 219, 238, 0.6);
    border-radius: 13px;

    width: 393px;
    height: 201px;

    @include mq(mobile) {
        max-width: 393px;
        width: 80%;
        height: 158px;
    }
}
.no_match__text {
    color: #C4CAEE;

    margin-top: 31px;

    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    font-family: Manrope;

    @include mq(mobile) {
        font-size: 9px;
        line-height: 14px;
    }
}
.no_match__exception {
    color: #4F4A62;

    margin-top: 9px;

    font-weight: 800;
    font-size: 21px;
    line-height: 18px;
    font-family: Manrope;

    @include mq(mobile) {
        font-size: 16px;
        line-height: 14px;
    }
}
.no_match__link {
    margin-top: 45px;
    font-family: Manrope;
    font-weight: 500;

    a:hover {
        text-decoration: none;
    }

    @include mq(mobile) {
        margin-top: 21px;
        font-size: 13px;
        line-height: 25px;
    }
}
