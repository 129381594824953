.search {
    width: 100%;
}
.search__wrapper {
    display: flex;
    align-items: center;

    width: 100%;
}
.search__wrap {
    display: flex;
    align-items: center;
    background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 67.46%), #FFFFFF;
    box-shadow: 0px 4px 4px rgba(234, 234, 234, 0.25);

    width: 100%;

    padding: 8px 14px;

    border-radius: 30px;

    //.input__box {
    //    border: none !important;
    //    outline: none;
    //}
    //.input__control {
    //
    //}
}
.search__search {
    cursor: pointer;
}
.search__input {
    background-color: transparent;
    color: #5F587B;

    width: 100%;

    padding: 0 30px 0 0;

    border: none;
    outline: 0;

    font-family: Manrope;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}
.search__close {
    position: relative;

    cursor: pointer;

    &:before {
        content: "";

        position: absolute;
        top: 50%;
        left: -14px;
        transform: translateY(-50%);

        background-color: #F7F6F9;

        width: 1px;
        height: 17px;
    }
}
.search__tags {
    display: flex;
    flex-wrap: wrap;

    margin-top: 8px;
    margin-left: -8px;
}
.search__tag {
    position: relative;
    background: linear-gradient(0deg, rgba(161, 157, 254, 0.606971), rgba(161, 157, 254, 0.606971)), #979ED9;
    color: #FFFFFF;
    overflow: hidden;

    max-width: 300px;

    padding: 2px 23px 2px 7px;

    margin-bottom: 8px;
    margin-left: 8px;

    border-radius: 3px;

    font-family: Manrope;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.search__tag_close {
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);

    cursor: pointer;
}
