@import "../../assets/styles/common";

.layout_page {
    width: 100%;
    //height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    // background-image: url(../../img/bg-fade.png);
    background-image: url(../../assets/img/bg-fade1.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 80px;
}

