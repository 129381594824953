@function breakpoint ($key, $nth: null) {
    $value: map-get($breakpoints, $key);
    @if $nth {
        @return nth($value, $nth)
    } @else {
        @return $value
    }
}

@mixin mq($query) {
    @if $query == desktop {
        @media screen and (min-width: breakpoint(desktop, 1)) {
            @content
        }
    }
    @if $query == mobile {
        @media screen and (max-width: breakpoint(mobile, 2)) {
            @content
        }
    }
}