@import "../../../assets/styles/common";

.Documents {
    max-width: 680px;
    width: 100%;
}

.Documents_frame {
    background-color: $white-color;
    max-width: 680px;
    width: 100%;
    min-height: 537px;
    margin: 31px 0;
    padding: 33px 20px;
    border-radius: $radius;

    @include mq(mobile) {
        padding: 23px 22px;
        margin: 29px 10px 43px;
        min-height: 0;
        width: auto;
    }
}

.Documents_alert {
    margin-bottom: 20px;
}

.Documents_tabs {
    @include mq(mobile) {
        order: 1;
        margin-top: 20px;
    }
}

.Documents_filters {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include mq(desktop) {
        margin-bottom: 24px;
    }

    @include mq(mobile) {
        flex-direction: column;
    }

    //& > * {
    //    flex: 1 1 auto;
    //    @include mq(desktop) {
    //        margin-bottom: 0;
    //        &:first-child {
    //            flex: 0 0 auto;
    //            width: 33%;
    //            margin-right: 12px;
    //        }
    //    }
    //
    //    @include mq(mobile) {
    //        margin-bottom: 20px;
    //    }
    //}
}
.Documents_filters_wrap {
    display: flex;
    justify-content: space-between;
}

.Documents_title{
    font-size: $text-size;
    color: $color-text-dark;
    font-family: Manrope;
    font-weight: 800;

    @include mq(mobile) {
        font-size: 16px;
        line-height: 14px;
    }
}

.Documents_filter_side{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Documents_icon_first {
    padding-right: 22px;

    cursor: pointer;

    &:hover {
        & path {
            fill: #30B6EC;
        }
    }

}

.Documents_icon_last {
    padding-left: 15px;
    border-left: 2px solid #F7F6F9;

    cursor: pointer;

    &:hover {
        & rect {
            fill: #30B6EC;
        }
        & circle {
            stroke: #30B6EC;
        }
        & path {
            stroke: #30B6EC;
        }
    }
}

.Documents_search {
    display: none;
}

.Documents_items{
    font-size: 14px;
    color: $color-text-grey;
    margin-bottom: 15px;
    padding-right: 11px;
    font-family: Roboto;
    font-weight: normal;

    @include mq(mobile) {
        font-size: 15px;
        line-height: 13px;
    }
}

.Documents_signed{
    min-height: 287px;
    //overflow: auto;
    @include mq(mobile) {
        min-height: 0;
    }
}

.Documents_signed::-webkit-scrollbar {
    display: none;
  }

.Documents_unsigned {
    min-height: 67px;
}

.Documents_more {
    display: flex;
    justify-content: center;
    margin-bottom: 29px;
    font-size: $textSizeXS;
    font-family: Roboto;
    font-weight: 500;

    span {
        cursor: pointer;
        color: $primary-color-3;

        &:hover {
            color: $primary-color-1;
        }
    }
}

.Documents_count {
    margin: 0 11px;
    color: $primary-color-1;
    font-size: $textSizeXS;
}

