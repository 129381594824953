@font-face {
    font-family: "Hind";
    src: local("Hind"),
    url(../../fonts/Hind-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Manrope";
    src: local("Manrope"),
    url(../../fonts/Manrope-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Roboto";
    src: local("Roboto"),
    url(../../fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Futura-Medium";
    src: local("Futura-Medium"),
    url(../../fonts/FuturaPT-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Futura-Light";
    src: local("Futura-Light"),
    url(../../fonts/FuturaPT-Light.ttf) format("truetype");
}
