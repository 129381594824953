@import "../../../assets/styles/common";

.step_second {
    margin-top: 15px;
}
.step_second__block {
    padding: 60px 0;

    margin-bottom: 12px;

    border: 2.6px solid rgba(118, 182, 255, 0.14);
    border-radius: 14px;

    @include mq(mobile) {
        padding-top: 24px;
        padding-bottom: 35px;
        margin-top: 10px;
    }
}
.step_second__title {
    color: #4F4A62;

    font-family: Manrope;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}
.step_second__row {
    display: flex;
    justify-content: center;
}
.step_second__input {
    max-width: 317px;
    width: 100%;

    margin-top: 20px;

    .input__box {
        border: none;

        background: linear-gradient(180deg, #FBFBFB 0%, #FFFFFF 67.46%), #FFFFFF;
        box-shadow: 0px 4px 4px rgba(234, 234, 234, 0.25) !important;
        border-radius: 30px;
    }
    .input__input-wrapper {
        display: flex;
        justify-content: center;
    }
    .input__control {
        color: #868FDB;

        width: 100%;

        font-family: Manrope;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;

        text-align: center;
        text-transform: lowercase;

        &::placeholder {
            font-family: Manrope;
            font-weight: 800;
            font-size: 14px;
            line-height: 18px;

            text-align: center;
            //text-transform: capitalize;

            color: #E8E7EC;
        }
    }
    .input__sub {
        text-align: center;
    }
}
.step_second__input--inn .input__control {
    color: #5F587B;
}
