@import "../../../../assets/styles/common";
.notification {
    position: absolute;
    bottom: 27px;
    left: -57px;

    z-index: 9999;

    @include mq(mobile) {
        display: none;
    }
}
.notification__wrap {
    position: relative;

    background: #ffffff;
    box-shadow: 0px 13px 37px rgba(211, 219, 238, 0.46);

    width: 260px;
    min-height: 137px;

    padding: 20px 23px;

    border-radius: 13px;

    &:before {
        content: "";
        position: absolute;
        bottom: -9px;
        left: 76px;
        transform: rotate(45deg);

        display: block;
        background: #ffffff;

        width: 17px;
        height: 17px;
    }
}
.notification__close {
    position: absolute;
    top: 20px;
    right: 23px;

    cursor: pointer;
}
.notification__content {
    color: #5F587B;

    min-height: 72px;

    padding-right: 25px;

    font-family: Manrope;
    font-size: 13px;
    font-weight: bold;
    line-height: 22px;
}
.notification__next {
    text-align: right;

    color: #868FDB;

    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;

    cursor: pointer;
}
