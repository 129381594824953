@import "../../assets/styles/common";

.Footer {
    display: flex;
    align-content: center;
    width: 100%;
    font-family: Roboto;
    font-weight: normal;
    @include mq(desktop) {
        padding: 0 40px 25px;
    }
    @include mq(mobile) {
        flex-direction: column-reverse;
    }
}

.Footer_copyright {
    width: 24%;
    padding: 5px;
    font-size: 15px;
    font-family: Futura-Medium;
    color: #5EB1E9;
    white-space: nowrap;
    @include mq(mobile) {
        text-align: center;

        width: 100%;

        margin-top: 48px;
    }
}

.Footer_nav {
    display: flex;
    @include mq(mobile) {
        display: block;
    }
}

.Footer-item {
    padding: 6px 0 0 87px;
    color: $primary-color-2;
    text-decoration: none;
    font-size: 12px;

    @include mq(mobile) {
        text-align: center;
        display: block;

        padding: 0;

        margin-bottom: 8px;
    }
}


