.step_third {
    margin-top: 77px;
}
.step_third__block {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 65px;

    &--video {
        background: #F8FBFF;

        padding: 43px 0 49px;

        border-radius: 14px;
    }
}
.step_third__title {
    margin-bottom: 30px;

    font-family: Manrope;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}
.step_third__text {
    color: #8E8AA1;

    max-width: 360px;

    margin-bottom: 27px;

    font-family: Roboto;
    font-size: 11px;
    line-height: 19px;

    text-align: center;
}
.step_third__wrap {
    max-width: 527px;
    width: 100%;
}
