.Title {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    color: #000;
}

.subtitle {

}

.Text {
    margin: 0;
}

.Text__lg {
    font-size: $textSizeLG;
}

.Text__md {
    font-size: $textSizeMD;
}

.Text__sm {
    font-size: $textSizeSM;
}

.Text__muted {
    color: $color-gray-muted;
}

.Text__error {

}

.link {

}

.icon {

}

.list {

}

.break-all {
    word-break: break-all;
}

.nowrap {
    white-space: nowrap;
}